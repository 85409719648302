import logo from './transparentlightmode.png';
import './App.css';

function Text(paragraph) {
  return <i className="italic">{paragraph}</i>
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
        <h1 className="slogan">
          Removing the {Text("Artificial")} from Artificial Intelligence.
        </h1>
        <div className="subtitles"><p>Interpretability | Editability | Controllability | Data-Centrism</p></div>
        <a
          className="App-link"
          href="mailto:kevin@prehoc.io"
          target="_blank"
          rel="noopener noreferrer"
        >
          Coming Soon (Email Us)
        </a>
      </header>
      <p>© 2023 PreHoc Labs. All rights reserved.</p>
    </div>
  );
}

export default App;
